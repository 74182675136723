import React, {useContext, useEffect} from "react";
import {Row, Col, Alert, CloseButton, Container} from "react-bootstrap";
import {
    NOTIFICATIONS_ALERTS_READ_URL,
    NOTIFICATIONS_ALERTS_URL,
    NOTIFICATIONS_DECREMENT_READ_COUNT_URL
} from "service/UrlConstant";
import {SecurityContext} from "context/security";
import {HomepageContext} from "./context";
import {ToastContext} from "../../context/Toast";
import {UserPreferencesContext} from "../../context/userpreferences";
import parse from 'html-react-parser';


export default function PortalAlert(props) {

    const { loadedAlertsCallback} = {...props};
    const { portalAlertState, setPortalAlertState} = useContext(HomepageContext);
    const { error} = useContext(ToastContext);
    const {alertUnreadCountState, setAlertUnreadCountState} = useContext(UserPreferencesContext)
    const urlRegex = /(https?:\/\/[^\s\}\)\]\{\[\(]+)/g;

    const {
        makeGetRequest,
        makePostRequest,
        makeGetRequestNoParams
    } = useContext(SecurityContext);


    const decrementAlertCount = ()=> {
        const onSuccess = (response) => {
            const updateAlertUnreadCountState = {...alertUnreadCountState}
            if(updateAlertUnreadCountState.unreadCount > 0) {
                updateAlertUnreadCountState.unreadCount -= 1;
            }
            setAlertUnreadCountState(updateAlertUnreadCountState)
        }

        const onError = (err) => {
            console.error(err);
            error("Error occurred while decrementing alert");
        }
        makeGetRequestNoParams(NOTIFICATIONS_DECREMENT_READ_COUNT_URL, onSuccess, onError)
    }


    const markAlertAsRead = (notificationId) => {

        const onSuccessResponse = (response) => {

            const afterReadPortalAlertState = {...portalAlertState};
            const alertReadIndexToRemove = afterReadPortalAlertState.alerts.map(alert => alert.notificationId).indexOf(notificationId);
            if(alertReadIndexToRemove > -1){
                afterReadPortalAlertState.alerts.splice(alertReadIndexToRemove, 1);
                afterReadPortalAlertState.readIndex = alertReadIndexToRemove;
                decrementAlertCount();
            }
            else {
                console.log('Cant find index of alert to remove:' + notificationId);
            }
            setPortalAlertState(afterReadPortalAlertState);
            loadedAlertsCallback(afterReadPortalAlertState.alerts.length > 0);
        }

        const onErrorResponse = (err) => {
            console.error(err);
            error("Error occurred with closing alert");
        }

        makePostRequest([], NOTIFICATIONS_ALERTS_READ_URL  + "/" + notificationId, onSuccessResponse, onErrorResponse);
    }

    const loadNotificationAlerts = () => {
        const onSuccess = (response) => {
            const updatedPortalAlertState = {...portalAlertState};
            if (!response || !response.data || response.data.length === 0) {
                updatedPortalAlertState.alerts = [];
            } else {
                updatedPortalAlertState.alerts = response.data;
            }
            setPortalAlertState(updatedPortalAlertState);
            loadedAlertsCallback(updatedPortalAlertState.alerts.length > 0);
        }
        const onError = (err) => {
            console.error(err);
        }

        makeGetRequest(NOTIFICATIONS_ALERTS_URL, {}, onSuccess, onError);
    };

    useEffect(() => {
        loadNotificationAlerts();
    }, []);

    return (
        <Container fluid>
            {portalAlertState.alerts.map((alert, idx) => (
                <Row key={idx}>
                    <Col>
                        <Alert variant={alert.notificationType === 'ALERT'? 'warning' : 'info'} style={{padding: 0, marginBottom: 0}}>
                            <Container fluid style={{width: "100%"}}>
                                <Row>
                                    <Col>
                                        <Alert.Heading>
                                            <b style={{verticalAlign:'top'}}>{
                                                parse(alert.headerText.replace(urlRegex, function(url) {
                                                    return '<a href="' + url + '" target="_blank">' + url + '</a>';
                                                }))}</b>
                                        </Alert.Heading>
                                        <p>
                                            {parse(alert.descriptionText.replace(urlRegex, function(url) {
                                                return '<a href="' + url + '" target="_blank">' + url + '</a>';
                                            }))}
                                        </p>
                                    </Col>
                                    <Col xs={1}><CloseButton aria-label={"close"}
                                                             onClick={() => markAlertAsRead(alert.notificationId)}/>
                                    </Col>
                                </Row>

                            </Container>
                        </Alert>
                    </Col>
                </Row>
            ))}
        </Container>
    )
}
