import React, {useEffect, useState} from "react";
import {Container, Row, Col} from "react-bootstrap";
import ContactUsIcon from "components/ContactUsIcon";
import AuthenticationService from "../service/AuthenticationService";
import UserRoleService, {LEGAL_ENTITY_PERMISSION_KEY} from "../service/UserRoleService";

export default function AppFooter(props) {
    const baseurl = `${process.env.PUBLIC_URL}`
    const [isLegalEntity, setIsLegalEntity] = useState(false);

    const checkPermission = (menuKey) => {
        return UserRoleService.hasPermission(menuKey);
    }

    useEffect(() => {
        setIsLegalEntity(checkPermission(LEGAL_ENTITY_PERMISSION_KEY))
    },[])

    return(<>
        <hr/>
        <footer className="td-bg-colour-7 pb-4">
            <Container fluid className="px-6">
                <Row>
                    <Col className={`${props.isLogin?'text-left px-2':'text-center'} login-app-footer`}>
                        <br/>
                        {!props.isLogin &&
                            (
                                <>
                        Client Portal Users: {props.isLogin && <br/>} Please
                        <a className="td-cta td-link" href={isLegalEntity ? baseurl+"/Content/pdf/TDPS_Client_Portal_Disclaimers_and_Disclosures_0330.pdf" : baseurl+"/Content/pdf/TDPS Portal Disclaimers 11.27.24.pdf"} target="_blank"> click here to view important legal, disclaimer and disclosure information</a> for our client portal.<br/>
                        <a className="td-cta td-link" href={isLegalEntity ? baseurl+"/Content/pdf/TDPS_Financial_Statements_0330.pdf" : baseurl+"/Content/pdf/TDPS_Financial_Statements.pdf"} target="_blank">Financial Statements</a><br/>
                                </>
                            )
                        }
                    </Col>
                </Row>
            </Container>
            {!props.isLogin && !AuthenticationService.isInternalUser() && <ContactUsIcon/>}
        </footer>
    </>);
}
