import React, {useContext, useState} from 'react';
import * as Routes from "routes/Routes"
import {CloseButton, Col, Collapse, Container, Nav, Navbar, NavDropdown, Row} from "react-bootstrap";
import TDNavDropDown from "components/navbar/TDNavDropdown";
import {useHistory} from "react-router-dom";
import useWindowDimensions from "hooks/Dimensions";
import TDAccordionNavBar from "components/navbar/TDAccordionNavBar";
import TDDropDrownNavBar from "components/navbar/TDDropDrownNavBar";
import AuthenticationService from "service/AuthenticationService";
import {SecurityContext} from "context/security";
import PortalAlertsBadge from "./PortalAlertsBadge";
import {LoginContext} from "pages/login/context";
import {ReportsPanelContext} from "../pages/reports/context";
import {EntityAccountsContext} from "../components/entity-accounts/context";
import {QueriesPanelContext} from "../pages/query/context";
import {TradeCaptureContext} from "../pages/tradecapture/context";
import {getSessionAttribute, setSessionAttribute} from "../utils/SessionUtils";
import {SELECTED_ACCOUNT, SELECTED_ENTITY, TRADE_BREAK_STATE} from "../utils/Constants";
import {TradeBreakContext} from "../pages/tradecapture/context";
import {TRADECAPTURE_BREAKS} from "routes/Routes";


export default function TDMenu() {
    const {
        clearReportSearchAndLoadReportsHome,
    } = useContext(ReportsPanelContext);

    const {
        setShowAccountValidateAccount, setManagerDetails, entityAccountsOptions
    } = useContext(EntityAccountsContext);

    const {
        clearQueryPanelDisplayState,
    } = useContext(QueriesPanelContext);

    const {
        clearTradeSearchAndLoadTradingHome
    } = useContext(TradeCaptureContext)

    let history = useHistory();
    const [open, setOpen] = useState(false);
    const {setNeedLogin} = useContext(SecurityContext);
    const {resetFlagLeft,resetFlagRight} = useContext(SecurityContext);
    const profile = AuthenticationService.getProfile();
    const mfaUpdatePasswordUrl = AuthenticationService.getMfaUpdatePasswordUrl()
    const {clearLoginState} = useContext(LoginContext)
    const {setShowInfoModal} = useContext(TradeBreakContext);

    function navigateToPage(path) {
        clearQueryPanelDisplayState()
        clearReportSearchAndLoadReportsHome()
        clearTradeSearchAndLoadTradingHome()
        if (path === Routes.TRADECAPTURE_BREAKS){
            setShowInfoModal(false)
            setSessionAttribute(TRADE_BREAK_STATE, null)
        }
        else if (path === history.location.pathname){
            setSessionAttribute(SELECTED_ENTITY, {managerId:entityAccountsOptions[0].managerId, managerName: entityAccountsOptions[0].shortName+"-"+entityAccountsOptions[0].longName});
            setSessionAttribute(SELECTED_ACCOUNT, {accountId: '', name: '', accountCode: null});
            window.location.reload();
        }
        resetFlagLeft.current = true;
        resetFlagRight.current = true;
        setOpen(false);
        history.push(path);
    }

    function logout() {
        localStorage.clear();
        setNeedLogin(true);
        AuthenticationService.logout();
        clearLoginState();
    }

    function mfaUpdatePasswordNewTab(mfaUpdatePasswordUrl){
        window.open(mfaUpdatePasswordUrl, "_blank", "noreferrer")
    }

    const logo = <img alt="logo" src="/assets/img/tds_shield.svg" height="40" className="p-mr-2"></img>;
    const smallLogo = <img alt="logo" src="/assets/img/td-logo.png" height="40" className="p-mr-2"></img>;
    const {isDesktop, isTab, isMobile } = useWindowDimensions();
    const userName = isMobile?(<i className={'td-icon icon-regular td-icon-Login'} style={{ fontSize: '31px' }}/>):profile.fullName
    return (
        <>
            <Navbar collapseOnSelect  bg="light" variant="light">
                {(isTab || isMobile) && <i className={'td-icon icon-regular td-icon-hamburger p-2'} onClick={()=>setOpen(true)}></i>}

                <Navbar.Brand onClick={()=>{ navigateToPage(Routes.HOME); }}>
                    {isMobile && smallLogo}
                    {!isMobile && logo}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link className=' d-none d-sm-block mt-1' onClick={()=>{ navigateToPage(Routes.HOME); }} style={{lineHeight: -4, fontWeight:'normal', color:'black', fontFamily: 'TD Graphik SemiLight'}}>TD Prime Services</Nav.Link>
                    </Nav>
                    <Nav>
                        <PortalAlertsBadge/>
                        <TDNavDropDown  title={userName} id="collapsible-nav-dropdown" alignRight={true}>
                            <NavDropdown.Item className="tds-nav-item" onClick={()=>{navigateToPage(Routes.PROFILE)}}>Profile</NavDropdown.Item>
                            <NavDropdown.Item onClick={()=>{mfaUpdatePasswordNewTab(mfaUpdatePasswordUrl)}}>Settings</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={()=>{logout()}}>Logout</NavDropdown.Item>
                        </TDNavDropDown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            {isDesktop && (
                <Navbar collapseOnSelect expand="lg" className="tds-nav">
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="tds-nav-container">
                            <TDDropDrownNavBar navigateToPage={navigateToPage}/>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>)}

            {(isTab || isMobile) && (

                <Collapse in={open} dimension="width">
                    <Container id={'overlay'} xs={12} sm={6}>
                        <Row className={`overlay pl-3 ${isTab?'half-screen-width':''}`}>
                            <Col md={open ? 2 : "auto"} xs={12} sm={12} className={"td-vertical-divider-line-1"} >
                                <Container className="min-vh-100" fluid>
                                    <Row>
                                        <Col xs={12} style={{paddingTop:10, paddingLeft:5, paddingRight:0, paddingBottom:0}}>
                                            {smallLogo}
                                            <CloseButton className={'td-icon icon-regular'} aria-label={"close"} onClick={() => setOpen(false)}/>
                                        </Col>
                                        <Col xs={12} className={'px-0 pt-3 pb-0'}>
                                            <Row className={'d-block py-1 px-0'}><hr/></Row>
                                        </Col>
                                        <Col xs={12} style={{paddingTop:0, paddingLeft:5, paddingRight:0, paddingBottom:0}}>
                                            <Navbar collapseOnSelect expand="xs" className="tds-nav" expanded={true}>
                                                <Navbar.Collapse id="responsive-navbar-nav">
                                                    <Nav className="tds-nav-container">
                                                        <TDAccordionNavBar navigateToPage={navigateToPage}/>
                                                    </Nav>
                                                </Navbar.Collapse>
                                            </Navbar>
                                        </Col>

                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </Collapse>

                    )
                    }
        </>
    );
}
