export const BASE_URL = process.env.REACT_APP_BASE_URL_API
export const MFA_REDIRECT_URL = process.env.REACT_APP_MFA_REDIRECT_URL
export const MFA_LOGOUT_URL = process.env.REACT_APP_MFA_LOGOUT_URL
export const MFA_UPDATE_PASSWORD_URL = process.env.REACT_APP_MFA_SETTINGS_URL

/************** Login ********************/
export const AUTHENTICATE_MFA_URL = `${BASE_URL}/public/v1/mfaauthenticate`;
export const AUTHENTICATE_URL = `${BASE_URL}/public/v1/authenticate`;
export const REFRESH_URL = `${BASE_URL}/v1/refreshToken`;
export const RESET_URL = `${BASE_URL}/v1/user/password/reset`;
export const RESET_EXPIRED_URL = `${BASE_URL}/public/v1/user/password/expiredPasswordReset`;
export const LOGOUT_PATHNAME =  '/logout';

/************** Notifications ********************/
export const NOTIFICATIONS_URL = `${BASE_URL}/v1/notifications`;
export const NOTIFICATIONS_ALERTS_URL = `${BASE_URL}/v1/notifications/alerts`;
export const NOTIFICATIONS_ALERTS_UNREAD_COUNT_URL = `${BASE_URL}/v1/notifications/unread/count`;
export const NOTIFICATIONS_CLEAR_ALL_UNREAD_COUNT_URL = `${BASE_URL}/v1/notifications/clearAll`;
export const NOTIFICATIONS_DECREMENT_READ_COUNT_URL = `${BASE_URL}/v1/notifications/decrementCount`;
export const NOTIFICATIONS_ALERTS_READ_URL = `${BASE_URL}/v1/notifications/alerts/alert/read`;

/************** Preferences ********************/
export const PREFERENCES_URL = `${BASE_URL}/v1/user/preferences`;

/************** Report ********************/
export const REPORT_FOLDERS_URL = `${BASE_URL}/v1/reports/folders`;
export const REPORT_FOLDERS_FILES_URL = `${BASE_URL}/v1/reports/folders/folder/files`;
export const REPORT_FOLDERS_FILES_FILE_URL = `${BASE_URL}/v1/reports/folders/folder/files/file`;
export const REPORT_SAVED_REPORTS_URL = `${BASE_URL}/v1/reports/report/save`;

/************** Query ********************/
export const QUERIES = `${BASE_URL}/v1/queries`;
export const QUERIES_QUERY = `${BASE_URL}/v1/queries/query`;
export const QUERIES_QUERY_SAVE = `${BASE_URL}/v1/queries/query/save`;
export const QUERIES_QUERY_SAVED_USER = `${BASE_URL}/v1/queries/query/saved/user`;

/************** SSA ********************/
export const SHORT_SALE_URL_LOCATE = `${BASE_URL}/v1/sl/shortsale/weblocate`;
export const SHORT_SALE_URL_SEARCH_APPROVALS = `${BASE_URL}/v1/sl/shortsale/webapprovals`;
export const SHORT_SALE_URL_UPLOAD_FILE = `${BASE_URL}/v1/sl/shortsale/upload/validate`;
export const SHORT_SALE_URL_PERSIST_FILE = `${BASE_URL}/v1/sl/shortsale/upload/persist`;
export const SHORT_SALE_URL_GET_PERSIST_FILES = `${BASE_URL}/v1/sl/shortsale/upload/webLocateFiles`;
export const SHORT_SALE_URL_GET_PERSIST_FILE_DETAILS = `${BASE_URL}/v1/sl/shortsale/upload/webLocateFileDetails`;
export const SHORT_SALE_URL_DELETE_PERSIST_FILE = `${BASE_URL}/v1/sl/shortsale/upload/deleteWebLocateFile`;
export const SHORT_SALE_URL_RENAME_PERSIST_FILE = `${BASE_URL}/v1/sl/shortsale/upload/renameWebLocateFile`;

/************** Trades ********************/
export const TRADES_LIST_URL = `${BASE_URL}/v1/trades-list/`;
export const TRADES_SAVED_LIST_URL = `${BASE_URL}/v1/trades-list/save`;
export const TRADES_UPDATE_LIST_NAME_URL = `${BASE_URL}/v1/trades-list/save`;
export const TRADES_DELETE_LIST_URL = `${BASE_URL}/v1/trades-list/save`;
export const TRADES_SEARCH_URL = `${BASE_URL}/v1/trades/search`;
export const TRADES_SAVE_URL = `${BASE_URL}/v1/trades/save`;
export const TRADES_UPLOAD_VALIDATE_URL = `${BASE_URL}/v1/trades/upload/validate`;
export const TRADES_UPLOAD_SAVE_URL = `${BASE_URL}/v1/trades/upload/save`;
export const TRADES_REBOOK_URL = `${BASE_URL}/v1/trades/rebook`;
export const TRADES_CANCEL_URL = `${BASE_URL}/v1/trades/cancel`;
export const TRADES_LIST_TRADE_BREAKS = `${BASE_URL}/v1/trades-break/list`;
export const TRADES_BREAK_DETAIL_URL = `${BASE_URL}/v1/trades-break/detail`;

/************** Broker ********************/
export const BROKER_SEARCH_URL = `${BASE_URL}/v1/broker/manager`;

/************** Security ********************/
export const SECURITY_SEARCH_URL = `${BASE_URL}/v1/security/search`;
export const SECURITY_SEARCH_OPTIONS_URL = `${BASE_URL}/v1/security/search/options`;

/************** Security ********************/
export const CURRENCY_LIST_URL = `${BASE_URL}/v1/currency`;
export const CURRENCY_LIST_UNIQUE_URL = `${BASE_URL}/v1/currency/unique`;


/************** Wires ********************/
export const WIRES_WIRE_SEARCH = `${BASE_URL}/v1/wires/search`;
export const WIRES_TEMPLATES_URL = `${BASE_URL}/v1/wires/templates`;
export const WIRES_SAVE_URL = `${BASE_URL}/v1/wires/wire`;
export const WIRES_LIST = `${BASE_URL}/v1/wires/list`;
export const WIRES_WIRES_LIST_SAVE = `${BASE_URL}/v1/wires/list/save`;
export const WIRES_SAVED_WIRES_LIST_URL = `${BASE_URL}/v1/wires/list`;
export const WIRES_CURRENCY_URL =`${BASE_URL}/v1/wires/currencies`;
export const WIRES_APPROVERS = `${BASE_URL}/v1/wires/accounts/approvals`;
export const WIRES_APPROVE_REJECT_VALIDATE = `${BASE_URL}/v1/wires/accounts/approveRejectValidate`;
export const WIRES_APPROVE_REJECT_VALIDATE_AND_APPROVE_REJECT = `${BASE_URL}/v1/wires/accounts/approveRejectValidateAndApproveReject`;

export const WIRES_APPROVE_REJECT = `${BASE_URL}/v1/wires/approveReject`;

/*******************HOME******************/
export const HOME_TOP_LONG_POSITIONS = `${BASE_URL}/v1/positions/top/long`;
export const HOME_TOP_SHORT_POSITIONS = `${BASE_URL}/v1/position/top/short`;
export const HOME_ACCOUNT_BALANCES = `${BASE_URL}/v1/account/balance`;
export const HOME_USERS_CLIENTREP = `${BASE_URL}/public/v1/clientmaster/users/client_rep/user`;


/******************COMMON*******************/
export const COMMON_BUS_DATES = `${BASE_URL}/public/v1/common/busDates`;

