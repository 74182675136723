import AuthenticationService from "./AuthenticationService";

class UserRoleService {

    hasPermission(menuKey) {
        let userProfile = AuthenticationService.getProfile();
        if (userProfile == null) {
            return false;
        }

        let permissions = userProfile.permissions;

        if (permissions && permissions.includes(menuKey)) {
            return true;
        }

        return false;
    }

}

export default new UserRoleService();
export const HOME_MENU_PERMISSION_KEY = 'H';
export const REPORTS_MENU_PERMISSION_KEY = 'F';
export const QUERIES_MENU_PERMISSION_KEY = 'Q';
export const TRADE_MENU_PERMISSION_KEY = 'C';
export const WIRES_MENU_SUBMITTER_PERMISSION_KEY = '1';
export const WIRES_MENU_CLIENT_APPROVER_PERMISSION_KEY = '2';
export const WIRES_MENU_VIEW_ONLY_PERMISSION_KEY = '3';
export const SSA_MENU_PERMISSION_KEY = 'A';
export const TRADE_MENU_UPLOAD_PERMISSION_KEY = 'TU';
export const TRADE_MENU_BREAK_PERMISSION_KEY = 'TB';
export const LEGAL_ENTITY_PERMISSION_KEY = 'LN';